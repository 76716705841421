@import "../../global.scss";

.footer {
    background-color: rgb(12,12,12);
    display: flex;
    flex-direction: column;



 .contact{
     display: flex;
     flex: 10;
     @include mobile{
        flex-direction: column;
        
    }
     

    

    .left{
        flex: 8;
        background-color: rgb(18, 18, 18);
        display: flex;
        flex-direction: column;
        align-items: center;
        
        justify-content: space-around;
        

        .leftContainer{
            
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            @include mobile{
                margin-top: 20px;
                
            }

            .item{
                background-color: $mainColor;
                color: white;
                width: 300px;
                height: 50px;
                border-radius: 20px;
                margin-bottom: 30px;
                transition: all 1s ease;
                display: flex;
                align-items: center;
                cursor: pointer;
                text-decoration: none;
                @include mobile{
                    margin-bottom: 14px;
                    height: 55px;
                    display: flex;
                    
                }
                @include short{
                    width: 95%;
                    flex-direction: row;
                }


                &:hover{
                    width: 320px;
                    height: 52px;
                }

                .leftCard{
                    flex: 1;

                    .dscLogo{
                        height: 40px;
                        width: 40px;
                        margin-left: 10px;
                        color: rgb(12,12,12);
                        @include mobile{
                            height: 28px;
                            width: 28px;
                        }
                    }
                }

                .rightCard{
                    flex: 4;
                    color: rgb(12,12,12);
                    align-items: center;
                    @include mobile{
                        display: 1;
                    }

                    h3{
                        text-decoration: none;
                        text-shadow: none;
                        text-decoration-line: none;
                        @include mobile{
                            font-size: 16px;
                            align-self: center;
                        }
                    }
                    &.D{
                        color: rgb(12,12,12);
                        h3{
                            text-decoration: none;
                            text-shadow: none;
                            text-decoration-line: none;
                            @include mobile{
                                font-size: 13px;
                            }
                        }
                    }
                    
                }

                &.Mobile{
                    display: none;
                    @include mobile{
                        display: flex;
                    }
                }
                
                

            }
        }
    }

    .right{
        flex: 12;
        background-color: rgb(30, 30, 30);
        display: flex;
        flex-direction: column;
            align-items: center;
            justify-content: center;
            @include mobile{
                flex: 8;
                
            }
        .wholeRight{
           
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 75%;
            width: 100%;
            align-self: center;
            overflow: hidden;
            @include mobile{
                height: 90%;
                
            }

            h2{
                font-size: 30px;
                color: $mainColor;
                @include mobile{
                    font-size: 24px;
                    
                }
            }
    
            form{
                width: 70%;
                height: 70%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-around;
                @include mobile{
                    width: 80%;
                    height: 70%;
                    
                }
                @include muypeques{
                    width: 90%;
                    height: 80%;
                    
                }
            }
    
            input{
                width: 300px;
                height: 30px;
                font-size: 14px;
                color: rgb(255, 255, 255);
                background-color: rgb(17, 17, 17);
                border-radius: 10px;
                border: none;
                padding: 16px;
                box-sizing: border-box;
                margin-bottom: 7px;
                @include muypeques{
                    padding: 16px;
                    width: 200px;
                }
                

                &:focus{
                    outline: none;
                }
            }
    
            textarea{
                width: 300px;
                height: 200px;
                font-size: 14px;
                border-radius: 15px;
                border: none;
                color: rgb(255, 255, 255);
                background-color: rgb(17, 17, 17);
                resize: none;
                padding: 16px;
                box-sizing: border-box;
                margin-bottom: 12px;
                @include muypeques{
                    padding: 16px;
                    width: 200px;
                }
    
                &:focus{
                    outline: none;
                }
            }
    
            button{
                width: 150px;
                height: 30px;
                color: rgb(12,12,12);
                background-color: $mainColor;
                border: none;
                border-radius: 10px;
                font-weight: 500;
                cursor: pointer;
                @include mobile{
                    width: 200px;
                    height: 50px;
                    
                }
    
                &:focus{
                    outline: none;
                }
            }
    
            
    
        }
        span{
            color: $mainColor;
            overflow: hidden;
            margin-top: 30px;
        }

    }
  }
  .mapa{
    display: flex;
    flex: 3;
    background-color: $mainColor;
    @include mobile{
        display: none;
        
    }

    .mapaImg{
        width: 100%;
        margin-top: 14px;
    }
  }
    
}