@import "../../global.scss";

.topbar {
    background-color: rgb(12, 12, 12);
    width: 100%;
    height: 70px;
    margin: 0px;
    position: fixed;
    top: 0;
    z-index: 4;
    transition: all 1.5s ease;

    .wrapper{
        padding: 1px 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
         margin-left: 30px;
         @include mobile{
            margin-left: 0px;
            padding: 1px 15px;
        }


        .left{
            align-items: center;
            display: flex;
           


            .logo{
                font-size: 40px;
                font-weight: 100;
                text-decoration: none;
                color: inherit;
                margin-top: 5px;
                @include mobile{
                   
                }
            }

            .logoImage
            {
                height: 50px;
                width: 50px;
                object-fit: cover;
                object-position: 100% 0;
            }

            .itemContainer{
                display: flex;
                margin-left: 30px;
                align-items: center;
                @include mobile{
                    margin-left: 0px;
                    opacity: 0;
                    width: 85%;
                }
                @include muypeques{
                    display: 0;
                    width: 40%;
                    margin-left: 0px;
                }

                .basicInfo{
                    font-size: large;
                    margin-left: 10px;
                    align-items: center;
                    color: white;
                    @include mobile{
                        opacity: 0; 
                        height: 0;
                    }
                    @include muypeques{
                        display: 0;
                        height: 0;
                    }
                }

                .icon{
                    margin-left: 20px;
                    color: white;
                    @include mobile{
                        opacity: 0; 
                    }
                    @include muypeques{
                        display: 0;
                    }
                }
            }
        }

        .right{
            display: flex;
            flex-direction: row;
            justify-content: center;
            @include mobile{
                width: 400px;
            }
            @include muypeques{
                width: 400px;
            }


            .switch{
                margin-right: 40px;
                @include mobile{
                    margin-right: 340px;
                }
                @include muypeques{
                    margin-right: 320px;
                }
            }


            .hamburguer{
                width: 32px;
                height: 25px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                cursor: pointer;
                overflow-y: hidden;
                @include muypeques{
                    
                }
                @include mobile{
                    margin-left: -250px;
                }
                
                
                
                        }
                        .line1{
                            width: 100%;
                            height: 3px;
                            background-color: rgb(255, 255, 255);
                            transform-origin: left;
                            transition: all 2s ease;
                        }
                        .line2{
                            width: 100%;
                            height: 3px;
                            background-color: rgb(255, 255, 255);
                            transform-origin: left;
                            transition: all 2s ease;
                        }
                        .line3{
                            width: 100%;
                            height: 3px;
                            background-color: rgb(255, 255, 255);
                            transform-origin: left;
                            transition: all 2s ease;
                        }
        }
        
    }


    &.activeamarillo{
        background-color: $mainColor;
        
        .wrapper{
            .left{
                .itemContainer{
                    .icon{
                        color:rgb(12, 12, 12)
                    }
                    .basicInfo{
                        color: rgb(12, 12, 12);
                    }
                }
            }
        }
        
        

        .hamburguer{
            
            span{
                &:first-child{
                    background-color: black;
                    transform: rotate(45deg);
                }
                &:nth-child(2){
                    opacity: 0;
                }
                &:last-child{
                background-color: black;
                transform: rotate(-45deg);
                }
            }
        }

        
    }

    &.activenegro{
        background-color: rgb(12, 12, 12);
        
        
        
        

        .hamburguer{
            
            span{
                &:first-child{
                   
                    transform: rotate(45deg);
                }
                &:nth-child(2){
                    opacity: 0;
                }
                &:last-child{
                
                transform: rotate(-45deg);
                }
            }
        }

        
    }

    &.falseamarillo{
        &.amarillo{

            background-color: $mainColor;
        
        .wrapper{
            .left{
                .itemContainer{
                    .icon{
                        color:rgb(12, 12, 12)
                    }
                    .basicInfo{
                        color: rgb(12, 12, 12);
                    }
                }
            }
        }
        
        

        .hamburguer{
            
            span{
                &:first-child{
                    background-color: black;
                    
                }
                &:nth-child(2){
                    background-color: black;
                }
                &:last-child{
                background-color: black;
                
                }
            }
        }

        }
    }
}