@import "../../global.scss";

.intro{
    background-color: $mainColor;
    transition: all 1.5s ease;
    z-index: -2;
    display: flex;

    @include mobile{
        flex-direction: column;
        align-items: center;
    }
    

   

    .pepas{
        z-index: 3;
    }


    .left{
        flex:0.5;
        overflow: hidden;

        .imgContainer{
            margin-top: 130px;
            margin-right: 50px;
            z-index:2;
            width: 900px;
            height: 900px;
            background-color: rgb(12,12,12);
            border-radius: 50%;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            float:right;
            transition: all 2s ease;

            @include mobile{
                align-items: flex-start;
                width: 750px;
                height: 750px;
                margin-top: 50px;
            }
            @include short{
                align-items: flex-start;
                width: 650px;
                height: 650px;
                
            }
            
            .yo{
                width: 600px;
                margin-left: -100px;
                transition: all 2s ease;

                @include mobile{
                    width: 350px;
                    margin-left: 0;
                    margin-top: -100px;
                }
                @include short{
                    width: 300px;
                    margin-left: 0;
                    margin-top: -100px;
                }

                &.active{
                width: 300px;
                margin-left: 50px;
                transition: all 2s ease;
                @include mobile{
                    width: 150px;
                margin-left: 0px;
                    
                }

                }
            }
        }
    }
    .right{
        flex:0.5;
        overflow: hidden;
        align-items: center;
        position: relative;
        
        

        .wrapper{
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            padding-left: 50px;
            justify-content: center;
            transition: all 2s ease;
            @include mobile{
                padding-left: 0;  
            }

        h2{
            font-size: 35px;
            transition: all 1s ease;
            @include mobile{
                font-size: 19px;
                
            }

        }
        h1{
            font-size: 60px;
            margin: 10px 0;
            transition: all 1s ease;
            @include mobile{
                font-size: 30px;
                
            }
        }
        h3{
            font-size: 35px;
            transition: all 1s ease;
            @include mobile{
                font-size: 19px;
                
            }
            
        }
        .es{
            &.en-us{
                display: none;
            }
        }
        .en{
            &.es-CO{
                display: none;
                opacity: 0;
            }
        }

        span{
            font-size: 40px;
            color: $topBar;
            @include mobile{
                font-size: 21px;
                
            }
        }

        

        
    }

    .downlink{
        color:rgb(12,12,12);
        transition: all 1s ease; 
        text-decoration: none;

        .down{
            height: 100px;
            width: 100px;
            position: absolute;
            bottom: 10px;
            left: 30%;
            transition: all 1s ease;
            text-decoration: none;
            animation: arrowBlink 3s infinite;
        }

        @keyframes arrowBlink {
            100%{
                opacity: 40%;
            }
            
        }
    }
  

   

    }


    &.active{
        background-color: rgb(12,12,12);

        .imgContainer{
            background-color: $mainColor;
            width: 500px;
            height: 500px;
            margin-top: 100px;
            margin-right: 100px;
            @include mobile{
                width: 300px;
                height: 300px; 
                margin-top: 100px; 
                margin-right:220px;
                z-index: 4;
                overflow:visible;
                background-color: $yellow2;
            }
            @include short{
                width: 300px;
                height: 300px; 
                margin-top: 100px; 
                margin-right:220px;
                z-index: 4;
                overflow-y: hidden;
                background-color: $yellow2;
            }
            
        }

        .wrapper{
            color: $mainColor;
            margin-top: -150px;
            margin-left: 00px;
            @include mobile{
                margin-left: 0px;
                margin-top: -50px;
                left: 5%;
                
                overflow: visible;
                width: 90%;
            }
            @include short{
                margin-left: -40px;
                margin-top: -50px;
                left: 5%;
                
                overflow: visible;
                width: 90%;
            }
            h1{
                font-size: 40px;
                @include mobile{
                    font-size: 20px;
                }
            }
            h2{
                font-size: 25px;
            }
            h3{
                font-size: 15px;
            }
        }

        .left{
        flex:0.3;
        @include mobile{
            flex:0.34;
        }
        }

        .right{
        flex:0.7;
        @include mobile{
            width: 90%;
        }


        .down{
            left: 15%;
        }

        a{
            color:$mainColor;
        }
        }
    }
}

