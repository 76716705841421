@import "../../global.scss";

.portfoliolist{
    li{
        font-size: 16px;
        margin-right: 50px;
        padding: 7px;
        border-radius: 10px;
        cursor: pointer;

    &.active{
        background-color: $topBar;
        color: white;
    }
    }
}