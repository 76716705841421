@import "../../global.scss";


.works{
    background-color: rgb(12, 12, 12);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .rightArrow{
        font-size: 100px;
        position: absolute;
        right: 5%;
        color: $mainColor;
        cursor: pointer;
        @include mobile{
            display: none;
            
        }
        @include short{
            right: 5px;
        }
    }

    .leftArrow{
        font-size: 100px;
        position: absolute;
        left: 5%;
        color: $mainColor;
        cursor: pointer;
        @include mobile{
            display: none;
            
        }
        @include short{
            left: 5px;
        }
        @include tabla{
            
        }

    }

    .slider{
        height: 350px;
        display: flex;
        position: absolute;
        left: 0;
        transition: all 1s ease-in-out;
        @include mobile{
            height: 100vh;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            
            
        }

        .container{
        width: 100vw;
        align-items: center;
        justify-content: center;
        display: flex;
        @include mobile{
            height: 200px;
            margin-top: 20px;
            
            
        }
        


        .item{
            width: 700px;
            height: 100%;
            background-color: $mainColor;
            border-radius: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            @include mobile{
                width: 94%;
                height: 179px;
                
            }
            @include tabla{
            width: 600px;
            }
            @include muypeques{
                
            }


            .left{
                flex: 4;
                height: 80%;
                display: flex;
                align-items: center;
                justify-content: center;
                @include mobile{
                    height: 90%;
                    margin-left: 5px;
                    flex: 6;
                    
                }

                .leftContainer{
                    width: 85%;
                    height: 80%;
                    display: flex;
                    align-items: center;
                    justify-content: space-evenly;
                    flex-direction: column;
                    @include mobile{
                        width: 95%;
                        height: 95%;
                        flex: 6;
                        
                    }

                    h2{
                        font-size: 25px;
                        margin-bottom: 10px;
                        @include mobile{
                            font-size: 15px;
                            margin-bottom: 0;
                        }
                        
                    }
                    p{
                        font-size: 13px;
                        margin-bottom: 20px;
                        @include mobile{
                            font-size: 11px;
                            margin-bottom: 0;
                        }
                        @include muypeques{
                            font-size: 9px;
                        }
                    }

                }

                .link{
                    text-decoration: none;
                    font-size: 18px;
                    color: rgb(12, 12, 12);
                    @include mobile{
                        font-size: 14px;
                        
                    }
                    
                }
                
            }

            .right{
                flex: 8;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                @include mobile{
                    flex: 6;
                    
                }
                @include tabla{
                    flex: 5;
                }
                

                .bungo{
                    transform: rotate(-10deg);
                    height: 120%;
                    background-color: rgb(255, 252, 187);
                }

                .kimberly{
                    transform: rotate(-10deg);
                    height: 100%;
                    width: 80%;
                }

                .yo{
                    transform: rotate(-10deg);
                    height: 150%;
                    width:60%; 
                    background-color: rgb(255, 252, 187);
                }
            }
        }
        }
    }

    
}