$mainColor: #fcde67;
$topBar: #253CB0;
$yellow2: #FFE682;

$width: 821px;
$widthtabla: 1181px;
$height: 600px;
$muyPeques: 300px;

@mixin mobile{
        @media (max-width: #{$width}){
            @content
        }
};

@mixin short{
        @media (max-height: #{$height}){
            @content
        }
};

@mixin tabla{
    @media (max-width: #{$widthtabla}){
        @content
    }
};

@mixin muypeques{
    @media (max-width: #{$muyPeques}){
        @content
    }
};



