*
.switch {
  border: none;
  width: 60px;
  line-height: 24px;
  border-radius: 12px;
  
  position: relative;
  padding: 0 10px;
  color: rgb(0, 0, 0);
  background: #4d89a5;
  transition: all 0.5s;
  cursor: pointer;
  text-align: left;
  outline: none;
  overflow-y: hidden;
  
}

.switch__btn {
  width: 20px;
  line-height: 60px;
  height: 20px !important;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  right: 2px;
  top: 2px;
  transition: right 0.7s;
  color: #253CB0;
  overflow-y: hidden;
}

.close {
  background: #9e8c41;
  text-align: right;
}

.close .switch__btn {
  right: 38px;
}

.switch__btn_loading {
  animation: ani-loading 1s linear infinite;
  text-align: center;
}

.loading {
  opacity: .5;
  cursor: not-allowed;
}
.aver{
  background-color: black;
  height: 12px;
  width: 3px;
}

@keyframes ani-loading {
  from {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(360deg);
  }
}