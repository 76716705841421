@import "../../global.scss";

.portfolio {

    background-color: $mainColor;
    display: flex;
        flex-direction: column;
        align-items: center;
        top: 0;
        left: 0;
        position: relative;
        

    

    .barragonita{
        background-color: #FFE682;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        clip-path: polygon(0 0, 65% 0, 55% 100%, 0 100%);
        transition: all 8s ease;
        left: -1000px;
        opacity: 80%;
        align-self: left;
        z-index: 0;
        
        
        
    }

    .cartuchis{
        display: flex;
        flex-direction: column;
        align-items: center;
        top: 0;
        left: 0;
        z-index: 1;
        padding-top: 50px;
        @include mobile{
            padding-top: 30px;
        }

        h1{
            font-size: 50px;
            @include mobile{
                font-size: 30px;
            }
        }
        ul{
           
            margin: 10px;
            padding: 0;
            list-style: none;
            display: flex;
            @include mobile{
                margin: 0;
                flex-wrap: wrap;
                justify-content: center;
            }
            
        }
        li{
            font-size: 16px;
            margin-right: 50px;
            padding: 7px;
            border-radius: 10px;
            cursor: pointer;
            @include mobile{
                margin: 1px 15px;
            }

        &.active{
            background-color: $topBar;
            color: white;
        }
        }
        .container{
            width: 70%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            @include mobile{
                width: 100%;
                margin-top: 20px;
            }
            @include short{
                width: 100%;
                margin-top: 20px;
            }
            @include tabla{
                width: 100%;
                margin-top: 20px;
            }
            
            
            
            opacity: 100%;
            border-radius: 20px;
            z-index: 1;
        }

        .item{
            width: 220px;
            height: 150px;
            border-radius: 20px;
            border: 1px solid rgb(218, 218, 128);
            margin: 10px 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            z-index: 2;
            transition: 0.5s ease;
            
            @include mobile{
                width: 140px;
                height: 90px;
            }
            @include short{
                width: 130px;
                height: 90px;
            }
            @include muypeques{
                width: 90px;
                height: 50px;
            }
            

            h3{
                position: absolute;
                font-size: 20px;
                @include mobile{
                    font-size: 15px;
                }
                @include muypeques{
                    font-size: 9px;
                }
            }
            .imagenItem{
                width: 100%;
                height: 100%;
                object-fit: cover;
                z-index: 2;
            }

            &:hover{
                background-color: $topBar;

                img{
                    opacity: 0.2;
                    z-index: 0;
                }
            }
        }

         
    }

    &.movido{
        .barragonita{
            left: 0px;
            align-self: left;
        }
    }

   

    
}

