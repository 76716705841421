@import "./global.scss";

.app {
    height: 100vh;
    

    .sections{
        
        width: 100%;
        height: calc(100vh - 70px);
        
        position: relative;
        top: 70px;
        scroll-behavior: smooth;
        scroll-snap-type: y mandatory;
        background-color: #fcde67;
        scrollbar-width: 0;

        @include mobile{
            scroll-behavior: auto;
            scroll-snap-type: none;
        }
        
        &::-webkit-scrollbar{
            display: none;
            
        }

        > * {
            width: 100vw;
            height: calc(100vh - 70px);
            scroll-snap-align: start;
            @include mobile{
                scroll-snap-align: none;
            }
        }
    }
}