@import "../../global.scss";


.menu{

    
    width: 800px;
    height: 100vh;
    background-color: rgb(12,12,12);
    position: fixed;
    top: 0;
    right: -800px;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: center ;
    justify-content: center;
    clip-path: polygon(calc(100vh - 35vh) 0, 100% 0%, 100% 100%, calc(100vh - 55vh) 100%);
    
    transition: all 1.5s ease;
    @include mobile{
        clip-path: polygon(calc(100vh - 23vh) 0, 100% 0%, 100% 100%, calc(100vh - 33vh) 100%);
    }
    @include short{
        clip-path: polygon(calc(100vh - 5vh) 0, 100% 0%, 100% 100%, calc(100vh - 15vh) 100%);
    }
    @include muypeques{
        clip-path: polygon(calc(100vh) 0, 100% 0%, 100% 100%, calc(100vh) 100%);
    }
    
    

    .list{
        position: fixed;
        right: 50px;
        margin: 0px;
        padding: 0;
        list-style: none;
        font-size: 30px;
        font-weight: 300;
        
        z-index: 2;
        transition: all 1.5s ease;
        @include mobile{
            font-size: 19px;
            right: 15px;
        }
        
        li{
            margin-bottom: 25px;
            margin-right: 100px;
            margin-left: 100px;
           
            a{
                font-size: inherit;
                color: inherit;
                text-decoration: none;
            }
            &:hover{
                font-weight: 600;
            }
        }

        .i{
            position: relative;
            right: -600px;
            transition: all 2s ease;
            color: rgb(12,12,12);
        }
        .p{
            position: relative;
            right: -600px;
            transition: all 2.2s ease;
            color: rgb(12,12,12);
        }
        .w{
            position: relative;
            right: -600px;
            transition: all 2.4s ease;
            color: rgb(12,12,12);
        }
        .d{
            position: relative;
            right: -600px;
            transition: all 2.6s ease;
            color: rgb(12,12,12);
        }
        .f{
            position: relative;
            right: -600px;
            transition: all 2.8s ease;
            color: rgb(12,12,12);
        }
        
    }

    &.activeamarillo{
    right: 0;
    background-color: $mainColor;
    .list{
        .i{
            position: relative;
            right: -100px;
            @include mobile{
                right: -100px;
            }
        }
        .p{
            position: relative;
            right: -85px;
            @include mobile{
                right: -90px;
            }
        }
        .w{
            position: relative;
            right: -70px;
            @include mobile{
                right: -80px;
            }
        }
        .d{
            position: relative;
            right: -55px;
            @include mobile{
                right: -70px;
            }
        }
        .f{
            position: relative;
            right: -50px;
            @include mobile{
                right: -70px;
            }
           
        }
        
    }
        

    }

    &.activenegro{
        right: 0;
        background-color: rgb(12,12,12);
        
        .list{
            .i{
                position: relative;
                right: -105px;
                color: $mainColor;
                @include mobile{
                    
                }
                
            }
            .p{
                position: relative;
                right: -85px;
                color: white;
                @include mobile{
                    right: -95px;
                }
            }
            .w{
                position: relative;
                right: -70px;
                color: white;
                @include mobile{
                    right: -85px;
                }
            }
            .d{
                position: relative;
                right: -55px;
                color: white;
                @include mobile{
                    right: -65px;
                }
            }
            .f{
                position: relative;
                right: -50px;
                color: white;
                @include mobile{
                    right: -75px;
                }
               
            }
            
        }
            
    
        }

}
